export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '35.0.0',
    name: 'lingo2-web',
    versionDate: '2024-09-13T22:44:05.542Z',
    gitCommitHash: 'g99f520a26',
    gitCommitDate: '2024-09-12T15:19:05.000Z',
    versionLong: '35.0.0-g99f520a26',
    gitTag: 'v35.0.0',
};
export default versions;
