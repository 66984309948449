// Environment: canary

import { IEnvironment } from '@models/environment';
import versions from '../_versions';

const DEPLOYMENT_HOST = process.env.DEPLOYMENT_HOST;
const API_ENDPOINTS_HOST = process.env.API_ENDPOINTS_HOST;
const ASSETS_HOST = process.env.ASSETS_HOST;
const I18N_HOST = process.env.I18N_HOST;
const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY;
const SENTRY_DSN = process.env.SENTRY_DSN;

export const environment: IEnvironment = {
  access_token_key: 'APP_AUTH_ACCESS',
  env: 'canary',
  production: true,
  store_debug: false,
  cookie_domain: DEPLOYMENT_HOST,
  default_language: 'en',
  default_host: `https://${DEPLOYMENT_HOST}`,

  auth_url: `https://${API_ENDPOINTS_HOST}/web/auth`,
  account_url: `https://${API_ENDPOINTS_HOST}/web/account`,
  content_url: `https://${API_ENDPOINTS_HOST}/web/content`,
  comment_url: `https://${API_ENDPOINTS_HOST}/web/comments`,
  files_url: `https://${API_ENDPOINTS_HOST}/web/files`,
  messaging_url: `https://${API_ENDPOINTS_HOST}/web/messaging`,
  notificator_url: `https://${API_ENDPOINTS_HOST}/notificator`,
  chat_url: `https://${API_ENDPOINTS_HOST}/web/chat`,
  ws_url: `wss://${API_ENDPOINTS_HOST}/ws`,
  stripe_iframe_host: `https://${API_ENDPOINTS_HOST}/web/billing`,

  i18n_url: `https://${I18N_HOST}/i18n`,
  i18n_en_url: `https://${I18N_HOST}/i18n`,
  i18n_dev: false,

  assets_url: `https://${ASSETS_HOST}/`,

  /** https://metrika.yandex.ru/dashboard?id=62748217 */
  metrika_id: 62748217,
  metrika_webvisor: false,

  /** https://analytics.google.com/analytics/web/#/p273907640 */
  ga_id: 'G-F2LVXQETMY',

  fb_id: false,

  /** https://app.crisp.chat/website/2ca1e19f-89ee-4892-9cb5-725c1949d64a/inbox/ */
  crisp_website_id: '2ca1e19f-89ee-4892-9cb5-725c1949d64a',

  mobile_app_url: 'https://i76jk.app.link/onclass-com',
  ssr_strip_scripts: true,
  re_captcha_site_key: RECAPTCHA_KEY,

  builderio_api_key: '2a8392fb2d064b208d8eb3c0ed9ec27d',

  versions,

  sentry_dsn: SENTRY_DSN,
  sentrySampleRate: 1.0,

  billing_public_url: `https://${API_ENDPOINTS_HOST}/web/billing`,
  sendbird_app_id: '67B48B78-63C2-43A2-9841-2B4F393C62B4',

  /** https://console.firebase.google.com/u/0/project/onclass-11649 */
  firebaseOptions: {
    apiKey: 'AIzaSyA1QmWmvckFEHJrSBoT3qHFAfIsFfw-pts',
    authDomain: 'onclass-11649.firebaseapp.com',
    databaseURL: 'https://onclass-11649.firebaseio.com',
    projectId: 'onclass-11649',
    storageBucket: 'onclass-11649.appspot.com',
    messagingSenderId: '680468905548',
    appId: '1:680468905548:web:8ebd8b70aa0d3b7a3d3ee9',
  },

  webpush: {
    public_key: 'BAyGkR979wY8yNOOx4y8V9ea6o3D7WSTOL8eYRJ6PUxUO5HpBIANNq8VhMdL3eRSVcU7Hdd39AvDsIok69ubYFQ',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
