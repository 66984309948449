import { IEnvironment } from '@models/environment';
import versions from '../_versions';
import { environment as environmentLocal } from './environment.local';

export const environment: IEnvironment = {
  access_token_key: 'APP_AUTH_ACCESS',
  env: 'staging',
  production: true,
  store_debug: false,
  cookie_domain: 'onclass.local',
  default_language: 'en',

  auth_url: 'http://b.onclass.local/web/auth',
  account_url: 'http://b.onclass.local/web/account',
  content_url: 'http://b.onclass.local/web/content',
  comment_url: 'http://b.onclass.local/web/comments',
  files_url: 'http://b.onclass.local/web/files',
  messaging_url: 'http://b.onclass.local/web/messaging',
  notificator_url: 'https://b.onclass.local/notificator',
  chat_url: 'http://b.onclass.local/web/chat',
  default_host: 'http://onclass.local:4201',
  stripe_iframe_host: `https://b.onclass.local/web/billing`,

  i18n_url: 'https://onclass.tech/i18n',
  i18n_en_url: 'https://onclass.tech/i18n',
  i18n_dev: false,

  assets_url: '',

  ws_url: 'wss://b.onclass.tech/ws',

  /** https://metrika.yandex.ru/dashboard?id=62748217 */
  metrika_id: 62748217,
  metrika_webvisor: false,

  /** https://analytics.google.com/analytics/web/#/p273907640 */
  ga_id: 'G-F2LVXQETMY',

  fb_id: false,

  /** https://app.crisp.chat/website/2ca1e19f-89ee-4892-9cb5-725c1949d64a/inbox/ */
  crisp_website_id: '2ca1e19f-89ee-4892-9cb5-725c1949d64a',

  mobile_app_url: 'https://i76jk.app.link/onclass-com',
  ssr_strip_scripts: true,
  re_captcha_site_key: '6LfLKdocAAAAAEjQHzCAHPBg3E4GRGGEmjmBT-4A',

  builderio_api_key: '2a8392fb2d064b208d8eb3c0ed9ec27d',

  versions,

  /** Do not send local errors to Sentry */
  sentry_dsn: '',

  sendbird_app_id: '67B48B78-63C2-43A2-9841-2B4F393C62B4',

  /** https://console.firebase.google.com/u/0/project/onclass-11649 */
  firebaseOptions: {
    apiKey: 'AIzaSyA1QmWmvckFEHJrSBoT3qHFAfIsFfw-pts',
    authDomain: 'onclass-11649.firebaseapp.com',
    databaseURL: 'https://onclass-11649.firebaseio.com',
    projectId: 'onclass-11649',
    storageBucket: 'onclass-11649.appspot.com',
    messagingSenderId: '680468905548',
    appId: '1:680468905548:web:8ebd8b70aa0d3b7a3d3ee9',
  },

  webpush: {
    public_key: null,
  },

  ...environmentLocal,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
