<app-base-notification [base]="base">
  <p class="class-info">
    {{isSingle ? ('notifications.contract-signed-up.text_lesson' | translate) :
    isCourse ? ('notifications.contract-signed-up.text_course' | translate) : ('notifications.contract-signed-up.text_regular' | translate)}}
    <ng-template [ngIf]="params.meetings[0]">
      <a [href]="params.meetings[0]?.meeting_join_url">{{params.meetings[0]?.meeting_title}}</a>
    </ng-template>
  </p>

    <div class="meeting-info" *ngIf="params.meetings[0]">
      <p *ngIf="isCourse">{{'notifications.contract-signed-up.number_of_classes' | translate}}: {{params.meetings.length}}</p>
      <p *ngIf="isRegular">{{'notifications.contract-signed-up.every-week' | translate}}<span>, {{'user-service-checkout-wizard.every' | translate}} {{params.meetings[0]?.meeting_begin_at | date : 'EEEE'}}</span></p>
      <p>
        <ng-container *ngIf="!isCourse">{{'notifications.contract-signed-up.date_time' | translate}}: {{params.meetings[0]?.meeting_begin_at | date : 'dd/MM/yyyy HH:mm'}}</ng-container>
        <ng-container *ngIf="isCourse">{{'notifications.contract-signed-up.first_class' | translate}}: {{params.meetings[params.meetings.length - 1]?.meeting_begin_at | date : 'dd/MM/yyyy HH:mm'}}</ng-container>
      </p>
      <p *ngIf="isSingle">{{'notifications.contract-signed-up.student' | translate}}: <a [href]="studentLink">{{studentName}}</a></p>
    </div>
</app-base-notification>
